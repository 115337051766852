import React from "react"
import Pagination from './Pagination'
import ContentCentered from 'src/components/ContentCentered'
import ArchiveItem from './ArchiveItem'
import GetSettings from 'src/utils/GetSettings'

const InsightsArchive = ({ posts, numPages, currentPage, tagSlug }) => {

    const settings = GetSettings()

    return (
        <>
            <ContentCentered 
                blok={{
                    title: "Insights",
                    content: settings.insights_description,
                    toggle_user_info: true,
                    disable_hexagons: true
                }}
            />
            <section className="bg-white pb-20 lg:pb-28">
                <div className="relative mx-auto max-w-7xl px-6">
                    <div className="pt-10 grid md:grid-cols-2 lg:grid-cols-3 md:gap-x-4 gap-y-8">
                        {posts.map((item) => (
                            <ArchiveItem {...item.node} key={item.node.uuid}/>
                        ))}
                    </div>
                    <Pagination numPages={numPages} currentPage={currentPage} tagSlug={tagSlug} baseSlug={'insights'}/>
                </div>
            </section>
        </>
    )
}

export default InsightsArchive

import React from "react"
import { Link } from 'gatsby'
import resolveLink from 'src/utils/resolveLink'

const Pagination = ({numPages, tagSlug, currentPage, baseSlug}) => {
    return (
        numPages > 1 &&
        <nav aria-label="Page navigation" className="py-12">
            <ul className="text-center space-x-2 border-t border-gray-200">
                {Array.from({ length: numPages }, (_, i) => (
                    <li className="inline-block" key={i}>
                        <Link
                            className={`flex justify-center items-center hover:text-pink duration-300 -mt-px ${
                            currentPage === i + 1
                                ? "text-orange border-orange"
                                : "text-[#9C9C9C] border-transparent hover:text-orange"
                            } text-base h-6 w-6 border-t-2 duration-300 px-4 py-6`}
                            key={`pagination-number${i + 1}`}
                            to={resolveLink(`/${baseSlug}/${tagSlug ? `category/${tagSlug}/` : ''}${i === 0 ? "" : i + 1}`)}
                        >
                            <span>{i + 1}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default Pagination
